<template>
  <div class="guoziran_box">
    <div class="guoziran_container">
      <!-- :inline="true" -->
      <div class="search_val_container no_select" v-show="isForm">
        <div class="condition_list">
          <!-- {{optionsList}} -->
          <span class="option_item" v-for="(item, i) in optionsList"
                :key="i">{{mapOption[item.key]}}:
            {{item.val}}{{ i+1===optionsList.length?'':'；'}}</span>
        </div>
        <div class="change_search_btn_box">
          <span @click="onOpenSearchForm" class="change_search_btn">修改搜索条件</span>
        </div>
      </div>
      <el-collapse-transition>
        <el-form v-show="!isForm" ref="form" :model="form" label-width="100px" size="small">
          <el-form-item label="项目名称:">
            <el-input v-model="form.xmmc" placeholder="请输入项目名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人名称:">
            <el-input v-model="form.fzr" placeholder="请输入负责人名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="单位名称:">
            <el-input v-model="form.dwmc" placeholder="请输入单位名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="项目批准号:">
            <el-input v-model="form.xmpzh" placeholder="请输入项目批准号" clearable></el-input>
          </el-form-item>
          <el-form-item label="学科分类:">
            <!-- <el-input v-model="form.xkfl" placeholder="请输入负责人名称" disabled></el-input> -->
            <el-select v-model="form.xkfl" placeholder="请选择学科分类" @change="getoneSubjectList">
              <el-option v-for="(item,i) in subjectList" :key="i" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="一级学科:">
            <el-select v-model="form.yjxk" placeholder="请选择一级学科" :disabled="!form.xkfl"
                       @change="getTwoSubjectList">
              <el-option v-for="(item,i) in oneSubjectList" :key="i" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二级学科:">
            <el-select v-model="form.ejxk" placeholder="请选择二级学科" :disabled="!form.yjxk"
                       @change="getThreeSubjectList">
              <el-option v-for="(item,i) in twoSubjectList" :key="i" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="三级学科:">
            <el-select v-model="form.sjxk" placeholder="请选择三级学科"
                       :disabled="!form.yjxk || !form.ejxk">
              <el-option v-for="(item,i) in threeSubjectList" :key="i" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目类别:">
            <el-select v-model="form.xmlb" placeholder="请选择项目类别">
              <el-option v-for="(item,i) in projectCategoryList" :key="i" :label="item"
                         :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="money_item" label="项目金额:">
            <el-input @change="onMoneyChange(fromMoney)" placeholder="输入金额" v-model="fromMoney">
            </el-input>
            <span style="margin-right:5px;">至</span>
            <el-input @change="onMoneyChange(toMoney)" placeholder="输入金额" v-model="toMoney">
            </el-input>
            <span>万元</span>
            <i v-if="isWarningText" class="warning_text">请输入纯数字</i>
          </el-form-item>
          <el-form-item class="time_item" label="批准时间:">
            <el-date-picker :editable="false" v-model="time[0]" type="year" value-format="yyyy"
                            placeholder="选择年" :clearable="false" @change="selectTimeChange"
                            :picker-options="pickerOptions">
            </el-date-picker>
            <span style="margin:0 5px;">至</span>
            <el-date-picker :editable="false" v-model="time[1]" type="year" value-format="yyyy"
                            placeholder="选择年" :clearable="false" @change="selectTimeChange"
                            :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item class="options_form_item no_select">
            <div class="options_container">
              <p class="vip_warn">* 2023年国自然数据已更新，仅会员可见</p>
              <div class="btn_container">
                <span @click="onQueryList" class="query_btn">查询</span>
                <span @click="onResetBtn" class="reset_btn">重置</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-collapse-transition>
      <ul class="guoziran_list_box">
        <li v-for="(item, i) in goZiRanProjectList" :key="item.id">
          <h4 @click="goArticleListPage(item.projectId)" class="title">{{i+1}}. 题目: {{item.title}}
          </h4>
          <div class="option_box color">
            <span>负责人: {{item.owner}}</span>
            <span>项目编号: {{item.projectId}}</span>
            <span>执行时间: {{item.startTime + '至' + item.endTime}}</span>
            <span>批准年份: {{item.approveYear}}</span>
            <span>单位: {{item.organization}}</span>
            <span>金额: {{item.amount + '万'}}</span>
          </div>
          <p class="company color">项目类型: {{item.projectType}}</p>
          <p class="color">学科分类: 一级: {{item.categoryName1}}; 二级: {{item.categoryName2}}; 三级:
            {{item.categoryName3}}</p>
        </li>
      </ul>
      <placeholder :isShow="!isForm && isQuexing" />
      <div v-if="isMore && !isQuexing" class="loading morebtn_box no_select">
        <span @click="onMoreBtn" v-if="isMoreBtn" class=" hand">加载更多>></span>
        <span v-else>暂无更多</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getSelectList, getGzrSearchList, getDocumentListByXmpzh } from '@/api/guoZiRan'
import { mapGetters } from 'vuex'
import { blankPagePar } from '@/utils/index'
export default {
  data () {
    return {
      pickerOptions: {
        disabledDate: (timeval) => {
          return timeval > Date.now() || new Date(timeval).getFullYear() === 2024 || timeval === Date.now()
        }
      },
      // 搜索条件中文映射
      mapOption: {
        xmmc: '项目名称',
        fzr: '负责人',
        dwmc: '单位名称',
        xmpzh: '项目批准号',
        xkfl: '学科分类',
        yjxk: '一级学科',
        ejxk: '二级学科',
        sjxk: '三级学科',
        xmje: '项目金额',
        pzsj: '批准时间',
        xmlb: '项目类别'
      },
      form: {
        xmmc: '', // 项目名称
        fzr: '', // 负责人
        dwmc: '', // 单位名称
        xmpzh: '', // 项目批准号
        xkfl: '', // 学科分类(定值：医学科学部)
        yjxk: '', // 一级学科
        ejxk: '', // 二级学科
        sjxk: '', // 三级学科
        xmje: '', // 项目金额 5-50
        pzsj: '', // 批准时间 2019-2020 必填
        xmlb: '', // 项目类别
        pageNum: 1, // 页码
        pageSize: 10 // 每页条数
      },
      fromMoney: '', // 开始金额
      toMoney: '', // 结束金额
      time: ['2019', '2019'],
      oneSubjectList: [], // 一级学科
      twoSubjectList: [], // 二级学科
      threeSubjectList: [], // 三级学科
      projectCategoryList: [], // 项目类别
      isWarningText: false, // 项目金额提示状态位
      isForm: false, // 筛选表单状态
      isQuexing: false, // 缺醒提示状态位
      isMore: false, // 加载更多盒子状态位
      isMoreBtn: false, // 加载更多按钮状态
      goZiRanProjectList: [], // 国自然项目列表
      subjectList: []
    }
  },
  methods: {
    selectTimeChange (date) {
      if (date === '2023' && !this.userInfo.isHy) {
        this.$message.warning('2023年的国自然数据仅会员可见，您还不是会员，请重新选择~')
      }
    },
    getoneSubjectList () {
      this.form.yjxk = ''
      this.form.ejxk = ''
      this.form.sjxk = ''
      this.getOneSubjectList()
    },
    // 跳转到文献列表页
    async goArticleListPage (projectId) {
      const res = await getDocumentListByXmpzh({ xmpzh: projectId, pageNum: 1, pageSize: 5 })

      if (res.code !== 0 || res.data.length === 0) return this.$message.warning('暂无相关论文')
      console.log(res.data)
      blankPagePar('gzrArticleList', { projectId })
    },
    // 加载更多
    onMoreBtn () {
      this.form.pageNum++
      this.getGzrSearchList()
    },
    // 打开搜索表单
    onOpenSearchForm () {
      this.isForm = false
    },
    // 重置搜索条件
    resetOption () {
      this.form = this.$options.data().form
      this.fromMoney = ''
      this.toMoney = ''
      this.time = this.$options.data().time
      this.isWarningText = false
      this.isQuexing = false
      this.isMore = false
    },
    // 重置按钮
    onResetBtn () {
      this.$confirm('操作不可逆, 请谨慎操作', '确认将所有信息重置吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      }).then(() => {
        this.resetOption()
      }).catch(() => {
      })
    },
    // 查询列表
    async onQueryList () {
      if (!this.userId) return this.$login()
      if (this.isWarningText) return this.$message.warning('请查看项目金额填写是否正确')
      if ((this.fromMoney && !this.toMoney) || (!this.fromMoney && this.toMoney)) return this.$message.warning('请完善项目金额')
      console.log(this.time.includes('2023'))
      if (!this.userInfo.isHy && this.time.includes('2023')) return this.$message.warning('2023年的国自然数据仅会员可见，您还不是会员，请重新选择~')
      this.$myCount('PC-国自然查询')
      this.goZiRanProjectList = []
      this.form.pageNum = 1
      document.documentElement.scrollTop = 0
      this.getGzrSearchList()
    },
    // 获取列表数据
    async getGzrSearchList () {
      try {
        this.form.pzsj = this.time.join('-')
        if (this.fromMoney && this.toMoney) {
          this.form.xmje = this.fromMoney + '-' + this.toMoney
        } else {
          this.form.xmje = ''
        }
        const res = await getGzrSearchList(this.form)
        this.isMoreBtn = res.data && res.data.length >= this.form.pageSize
        this.goZiRanProjectList = [...this.goZiRanProjectList, ...res.data]

        if (this.goZiRanProjectList.length > 0) {
          this.isForm = true
          this.isQuexing = false
          this.isMore = true
        } else {
          this.isForm = false
          this.isQuexing = true
          this.isMore = false
        }
      } catch (error) {
        this.$message.error('获取项目列表失败')
      }
    },
    // 验证项目金额格式
    onMoneyChange (val) {
      this.isWarningText = !/^\d*\.?\d*$/.test(val)
    },
    // 获取接口
    async getSelectList (config) {
      const res = await getSelectList(config.options)
      this[config.arrName] = res.data
    },
    // 获取一级学科
    getOneSubjectList () {
      this.getSelectList({
        options: { field: 'yjxk', xkfl: this.form.xkfl },
        arrName: 'oneSubjectList'
      })
    },
    // 获取二级学科
    getTwoSubjectList () {
      this.form.ejxk = ''
      this.form.sjxk = ''
      this.getSelectList({
        options: { field: 'ejxk', parent: this.form.yjxk, xkfl: this.form.xkfl },
        arrName: 'twoSubjectList'
      })
    },
    // 获取三级学科
    getThreeSubjectList () {
      this.form.sjxk = ''
      this.getSelectList({
        options: { field: 'sjxk', parent: this.form.ejxk, xkfl: this.form.xkfl },
        arrName: 'threeSubjectList'
      })
    },
    // 获取项目类别
    getProjectCategoryList () {
      this.getSelectList({
        options: { field: 'xmlb' },
        arrName: 'projectCategoryList'
      })
    },
    // 获取学科分类
    getSubjectList () {
      this.getSelectList({
        options: { field: 'xkfl' },
        arrName: 'subjectList'
      })
    }

  },
  computed: {
    ...mapGetters(['userId', 'userInfo']),
    optionsList () {
      return Object.keys(this.form).filter(item => item !== 'pageNum' && item !== 'pageSize' && this.form[item]).map(item => ({ key: item, val: this.form[item] }))
    }
  },
  created () {
    this.getSubjectList()
    this.getProjectCategoryList()
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !this.userId) {
        this.isForm = false
        this.goZiRanProjectList = []
        this.resetOption()
      }
    })
  }
}
</script>
<style lang="less" scoped>
.guoziran_box {
  width: 100%;
  min-height: calc(100vh - 254px);
  background-color: #f5f7fb;
  .guoziran_container {
    width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
    background-color: #f5f7fb;
  }
  .search_val_container {
    position: sticky;
    top: 52px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0 20px 10px;
    background-color: #f5f7fb;
    .condition_list {
      flex: 1;
      .option_item {
        margin-right: 5px;
      }
    }
    .change_search_btn_box {
      padding-left: 50px;
      flex-shrink: 0;
    }
    .change_search_btn {
      cursor: pointer;
      color: #4b639f;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .el-form {
    position: sticky;
    top: 52px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px 0 0;
    margin-bottom: 20px;
    background-color: #fff;
  }
  /deep/ .el-form-item {
    width: 25%;
    margin-right: 0;
  }
  .el-select {
    width: 192px;
  }
  /deep/ .money_item {
    width: 26%;
    // display: flex;
    // align-items: center;
    .el-form-item__content {
      position: relative;
      display: flex;
      .el-input {
        width: 69px;
        margin-right: 5px;
        .el-input__inner {
          padding: 0 5px;
        }
      }
    }
    .warning_text {
      position: absolute;
      width: 88px;
      height: 20px;
      font-size: 12px;
      left: 0;
      color: #f56c6c;
      // right: 0;
      bottom: -10px;
    }
  }
  /deep/.time_item {
    width: 35%;
    .el-form-item__content {
      display: flex;
      justify-content: space-between;
      .el-date-editor {
        flex: 1;
      }
    }
  }
  /deep/.options_form_item {
    width: 100%;
    .el-form-item__content {
      margin-left: 0px !important;
      .options_container {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        justify-content: space-between;
        .vip_warn {
          padding-left: 30px;
          font-size: 12px;
          color: #fc493a;
        }
      }
      .btn_container {
        height: 100%;
        span {
          display: inline-block;
          position: relative;
          top: 0;
          border: 1px solid #4b639f;
          border-radius: 5px;
          margin-left: 20px;
          padding: 0 20px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          transform: top 0.5s;
          &:hover {
            top: -1px;
          }
        }
      }

      .query_btn {
        background-color: #4b639f;
        color: #fff;
      }
      .reset_btn {
        color: #4b639f;
      }
    }
  }
  .guoziran_list_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    li {
      width: 49%;
      padding: 20px;
      border-radius: 2px;
      background-color: #fff;
      margin-bottom: 2%;
      font-size: 13px;
      cursor: default;
      transition: all 0.3s;
      &:hover {
        box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        height: 42px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .option_box {
        display: flex;
        flex-wrap: wrap;
        span {
          width: 33.33%;
          margin-bottom: 6px;
        }
      }
      .company {
        margin-bottom: 6px;
      }
      .color {
        color: #666;
      }
    }
  }
  .morebtn_box {
    text-align: center;
    padding: 10px 0 20px;
    font-size: 16px;
    font-weight: 600;
    color: #4b639f;
    cursor: default;
  }
}
</style>
